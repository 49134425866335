'use strict';

/**
 * Helper for updating the search url based on the ajax request
 * @param {string} ajaxUrl The ajax url for the search query
 */
function updateUrl(ajaxUrl) {
    const baseUrl = new URL(window.location.href);
    const url = new URL(ajaxUrl, baseUrl);
    baseUrl.search = url.search;

    window.history.pushState({}, null, baseUrl);
}

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector)
        .empty()
        .html($updates.html());
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    $('.refinement').each(function () {
        const $currentHeader = $(this).find('.refinement-header');
        const $currentBody = $(this).find('.refinement-body');
        const $activeDiv = $results.find($(this).data('refinement'));
        $activeDiv
            .find('.refinement-header')
            .attr('class', $currentHeader.attr('class'))
            .attr('aria-expanded', $currentHeader.attr('aria-expanded'));
        $activeDiv.find('.refinement-body').attr('class', $currentBody.attr('class'));
    });

    updateDom($results, '.refinements');
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements': handleRefinements
    };

    // Update DOM elements that do not require special handling
    ['.grid-header', '.header-bar', '.product-grid', '.show-more', '.filter-bar'].forEach(function (
        selector
    ) {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });
}

/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @return {undefined}
 */
function getContent($element, $target) {
    var showMoreUrl = $element.data('url');
    $.spinner().start();
    $.ajax({
        url: showMoreUrl,
        method: 'GET',
        success: function (response) {
            $target.append(response);
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response) {
    var $tempDom = $('<div>').append($(response));
    var sortOptions = $tempDom.find('.grid-footer').data('sort-options').options;
    sortOptions.forEach(function (option) {
        $('option.' + option.id).val(option.url);
    });
}

/**
 * Update sticker based on current variation
 * @param {Object} stickerContainer stickerContainer
 * @param {String} stickerValue stickerValue
 */
function updateSticker(stickerContainer, stickerValue) {
    if (stickerValue.length > 0) {
        stickerContainer.find('img').attr('src', stickerValue);
        stickerContainer.removeClass('d-none');
    } else {
        stickerContainer.addClass('d-none');
    }
}

/**
 * Updates the product tile label
 * @param {Object} tileContainer tileContainer
 * @param {String} labelValue labelValue
 * @param {String} labelColor labelColor
 */
function updateProductTileLabel(tileContainer, labelValue, labelColor) {
    if (labelValue) {
        tileContainer.html(labelValue);
        if (labelValue) {
            tileContainer.css('color', labelColor);
            tileContainer.css('border-color', labelColor);
        }
        tileContainer.removeClass('d-none');
    } else {
        tileContainer.addClass('d-none');
    }
}

module.exports = {
    init: function () {
        $(window).bind('popstate', function () {
            $(window).unbind('popstate');
            window.location = location.href;
        });
    },
    // Display refinements bar when Menu icon clicked
    filter: function () {
        $('.search-results').on('click', '.filter-results', function () {
            $('body').addClass('mobile-refinement-bar-open');
        });
    },
    // Refinements close button
    closeRefinements: function () {
        $('.search-results').on('click', '.refinement-bar button.close, .filter-footer .filter', function () {
            $('body').removeClass('mobile-refinement-bar-open');
        });
    },
    // Handle sort order menu selection
    sort: function () {
        $('.search-results').on('change', '[name=sort-order]', function (e) {
            e.preventDefault();

            $.spinner().start();
            $(this).trigger('search:sort', this.value);
            $.ajax({
                url: this.value,
                data: { selectedUrl: this.value },
                method: 'GET',
                success: function (response) {
                    $('.product-grid')
                        .empty()
                        .html(response);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },
    // Show more products
    showMore: function () {
        $('.search-results').on('click', '.show-more button', function (e) {
            e.stopPropagation();
            var showMoreUrl = $(this).data('url');

            e.preventDefault();

            $.spinner().start();
            $(this).trigger('search:showMore', e);
            $.ajax({
                url: showMoreUrl,
                data: { selectedUrl: showMoreUrl },
                method: 'GET',
                success: function (response) {
                    $('.grid-footer').replaceWith(response);
                    updateSortOptions(response);
                    $('body').trigger('search:afterShowMore', e);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },
    // Handle refinement value selection and reset click
    applyFilter: function () {
        $('.search-results').on(
            'click change',
            '.refinement-link:not(.category), .filter-bar .btn, .filter-footer .reset, .form-check-input',
            function (e) {
                e.preventDefault();
                e.stopPropagation();

                if ($(this).hasClass('disabled')) return;

                const url = $(this).data('href');

                $.spinner().start();
                $(this).trigger('search:filter', e);
                var attributeId = '#' + $(this).find('span').last().attr('id');
                $.ajax({
                    url,
                    data: {
                        page: $('.grid-footer').data('page-number'),
                        selectedUrl: url
                    },
                    method: 'GET',
                    success: function (response) {
                        parseResults(response);

                        updateUrl(url);
                        $('body').trigger('search:afterFilter', e);
                        $.spinner().stop();
                        $(attributeId).parent('button').focus();
                    },
                    error: function () {
                        $.spinner().stop();
                        $(attributeId).parent('button').focus();
                    }
                });
            }
        );
    },

    showContentTab: function () {
        // Display content results from the search
        $('.search-results').on('click', '.content-search', function () {
            if ($('#content-search-results').html() === '') {
                getContent($(this), $('#content-search-results'));
            }
        });

        // Display the next page of content results from the search
        $('.search-results').on('click', '.show-more-content button', function () {
            getContent($(this), $('#content-search-results'));
            $('.show-more-content').remove();
        });
    },
    toggleFilter: function () {
        $('.refinement-bar-toggle').on('click', function () {
            $(this).closest('#product-search-results').toggleClass('refinement-bar-closed');
        });
    },
    toggleMoreLess: function () {
        $('.seo-desc-toggle').on('click', function () {
            let $wrapper = $(this).closest('.category-seo-description');
            $('.category-seo-description-text', $wrapper).toggleClass('seo-text-ellipsis');
            $('.read-more-less-txt', $wrapper).toggleClass('d-none');
            if ($wrapper.hasClass('msg-slideup')) {
                $wrapper.removeClass('msg-slideup');
                $wrapper.addClass('msg-slidedown');
            } else {
                $wrapper.removeClass('msg-slidedown');
                $wrapper.addClass('msg-slideup');
            }
        });
        $('.category-seo-description').each(function () {
            let $wrapper = $(this);
            if ($(window).width() > 768 && ($('.category-seo-description-span', $wrapper).width() < $('.category-seo-description-text', $wrapper).width())) {
                $('.seo-desc-toggle', $wrapper).addClass('d-none');
            }
        });

        /* start: footer section */
        let $seoFooter = $('.category-seo-description-footer');
        let $footerSection = $('.category-seo-description-wrapper.collapsible', $seoFooter);
        if ($footerSection.length && $footerSection.height() >= $('.category-seo-description-content', $footerSection).height()) {
            $footerSection.removeClass('collapsible');
        }
        $('.seo-desc-collapse-toggle', $seoFooter).on('click', function () {
            let $target = $(this).siblings('.category-seo-description-wrapper.collapsible');
            if ($target.hasClass('active')) {
                $target.removeClass('active');
                $target.height($('.category-seo-description-content', $target).height());
            } else {
                $target.addClass('active');
                $target.height('');
            }
        });
        /* end: footer section */
    },
    swatchHover: function () {
        $('body').off('mouseenter', '.product-tile .swatch').on('mouseenter', '.product-tile .swatch', function () {
            var productTile = $(this).closest('.product-tile');
            var productSticker1 = productTile.find('.stickers .product-sticker1');
            var productSticker2 = productTile.find('.stickers .product-sticker2');
            var productSticker3 = productTile.find('.stickers .product-sticker3');
            var productTileLabel = productTile.find('.product-tile-label');
            updateSticker(productSticker1, $(this).attr('data-sticker1'));
            updateSticker(productSticker2, $(this).attr('data-sticker2'));
            updateSticker(productSticker3, $(this).attr('data-sticker3'));
            updateProductTileLabel(productTileLabel, productTileLabel.attr('product-tile-label'), productTileLabel.attr('product-tile-label-color'));

            productTile.find('img.tile-image').attr('src', $(this).attr('src'));
        });
        $('body').off('mouseleave', '.product-tile .swatches').on('mouseleave', '.product-tile .swatches', function () {
            var productTile = $(this).closest('.product-tile');
            var productSticker1 = productTile.find('.stickers .product-sticker1');
            var productSticker2 = productTile.find('.stickers .product-sticker2');
            var productSticker3 = productTile.find('.stickers .product-sticker3');
            var productTileLabel = productTile.find('.product-tile-label');
            updateSticker(productSticker1, productSticker1.find('.sticker-image').attr('data-default-sticker1'));
            updateSticker(productSticker2, productSticker2.find('.sticker-image').attr('data-default-sticker2'));
            updateSticker(productSticker3, productSticker3.find('.sticker-image').attr('data-default-sticker3'));
            updateProductTileLabel(productTileLabel, productTileLabel.attr('product-tile-label'), productTileLabel.attr('product-tile-label-color'));

            productTile.find('img.tile-image').attr('src', productTile.find('img.tile-image').attr('data-src'));
        });
    },
    tileHover: function () {
        var initTileHover = function initTileHover() {
            $('.product-tile .image-container').on('mouseenter', function () {
                if ($(this).find('img.tile-image').attr('data-alternate-image-src')) {
                    $(this).find('img.tile-image').attr('src', $(this).find('img.tile-image').attr('data-alternate-image-src'));
                }
            });
            $('.product-tile .image-container').on('mouseleave', function () {
                if ($(this).find('img.tile-image').attr('data-alternate-image-src')) {
                    $(this).find('img.tile-image').attr('src', $(this).find('img.tile-image').attr('data-src'));
                }
            });
        };

        initTileHover();
        $('body').on('search:afterShowMore search:afterFilter', function () {
            initTileHover();
        });
    }
};
